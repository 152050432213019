@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.cv {
    margin: 0 1rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
        @include desktop-margins;
        padding-bottom: 0;
        flex-direction: row;
        align-items: flex-start;
    }

    &__headshot {
        margin: 5rem 0;
        width: 50%;
        object-fit: contain;
        box-shadow: 10px 10px 0px 3px rgba(255,173,244,0.5);
        
        @include desktop {
            margin: 1.5rem 0 0 0;
            width: 25rem;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include desktop {
            padding-left: 3rem;
            align-items: flex-start;
            width: 80%;
            text-align: left;
        }
    }

    &__about-title {
        padding: 1rem 0 2rem;
        font-size: 1.75rem;
        text-transform: uppercase;
        text-align: center;
        color: $basic-white;
        
        @include desktop {
            font-size: 2.25rem;
            text-transform: uppercase;
            text-align: left;
        }
    }

    &__about-me-content {
        padding: 0 0 1rem 0;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $basic-white;

        @include desktop {
            padding: 0 0 0.5rem 0;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }

    &__about-content {
        padding: 0 1rem;
        color: $basic-white;

        @include desktop {
            padding: 0;
        }
    }

    &__subsections-container {
        display: flex;
        flex-direction: column;

        @include desktop {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__about-subsection {

        @include desktop {
            padding: 2rem 0 0;
            width: 90%;
        }
    }

    &__subsection-title {
        padding: 1.5rem 0 0.5rem 0;
        font-size: 1.25rem;
        color: $basic-white;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 0.25rem;
        text-decoration-color: $secondary-pink;

        @include desktop {
            padding: 0 0 0.75rem 0;
            font-size: 1.25rem;
        }
    }

    &__skills-list {
        padding-bottom: 0.5rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $basic-white;

        @include desktop {
            justify-content: flex-start;
        }
    }

    &__subsection-content-subheader {
        font-size: 1rem;
        line-height: 1.25rem;
        color: $basic-white;

        @include desktop {
            font-size: 1.1rem;
            line-height: 1.75rem;
            width: 100%;
        }

        &--padded {
            color: $basic-white;

            @include desktop {
                padding-top: 1rem;
                line-height: 1.75rem;
            }
        }
    }

    &__subsection-content {
        padding-bottom: 1.5rem;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $basic-white;

        @include desktop {
            padding-bottom: 0;
            width: 90%;
            font-size: 1.1rem;
        }
    }

    &__subsection-skills-content {
        margin: 0.5rem 0.5rem 0;
        padding: 0.5rem 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        width: fit-content;
        height: fit-content;
        background-color: rgba(255,173,244,0.5);
        font-size: 1rem;
        line-height: 1.25rem;
        color: $basic-white;

        @include desktop {
            margin: 0.5rem 0.75rem 0.5rem 0;
            padding: 0.5rem 0.75rem;
            font-size: 1.1rem;
        }
    }
}