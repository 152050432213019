@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.not-found {
    padding-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include desktop {
        padding-top: 15rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title-container {
        display: flex;
    }

    &__title {
        padding: 0 1rem;
        font-size: 1.75rem;
        text-transform: uppercase;
        text-align: center;

        @include desktop {
            padding: 0;
            font-size: 6rem;
        }

        &--hidden {
            display: none;

            @include desktop {
                display: flex;
                align-items: center;
                font-size: 6rem;
            }
        }
    }

    &__subtitle-container {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include desktop {
            padding: 3rem 0;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
        }
    }

    &__subtitle {
        padding: 2rem 0;
        font-size: 1.25rem;
        font-weight: 200;
        text-align: left;

        @include desktop {
            padding: 0 2rem 0 0;
            width: 18rem;
            font-size: 2.25rem;
            text-align: right;
        }
    }

    &__animation {
        width: 75px;
        aspect-ratio: 1; 
        display: grid;

        &:before,
        &::after {
            content: "";
            grid-area: 1/1;
            width: 35px;
            aspect-ratio: 1;
            box-shadow: 0 0 0 3px $basic-black inset;
            filter: drop-shadow(40px 40px 0 $basic-black);
            animation: load 2s infinite alternate;
        }

        &:after {
            margin: 0 0 0 auto; 
            filter: drop-shadow(-40px 40px 0 $basic-black);
            animation-delay: -1s;
        }
    }
}

@keyframes load {
    0%,10%   {border-radius:0}
    30%,40%  {border-radius:50% 0}
    60%,70%  {border-radius:50%}
    90%,100% {border-radius:0 50%}
}