@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.landing {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        @include desktop {
            padding: 10rem 0;
            width: 100%;
        }
    }

    &__title {
        font-size: 2.5rem;
        text-transform: uppercase;

        @include desktop {
            @include desktop-title;
        }
    }

    &__subtitle-content {

        @include desktop {
            display: flex;
        }
    }

    &__subtitle {
        padding: 3rem 1rem 0;
        width: 100%;
        font-size: 1.5rem;
        text-align: center;

        @include desktop {
            margin: 0;
            padding: 0;
            width: 100%;
            @include desktop-subtitle;
        }

        &--hidden {
            display: none;

            @include desktop {
                display: flex;
                font-size: 2.5rem;
            }
        }
    }
}