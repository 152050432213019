@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.home {
    overflow: auto;

    &__cv {
        background-color: $basic-black;

        @include desktop {
            padding: 5rem 0 7rem;
        }
    }

    &__projects {
        background-color: $basic-black;

        @include desktop {
            margin: 0 auto;
            padding: 1rem 0 5rem;
        }
    }

    &__contact {

        @include desktop {
            margin: 0 auto;
        }
    }
}