@use "./styles/partials/breakpoints" as *;
@use "./styles/partials/colors" as *;
@use "./styles/partials/mixins" as *;
@use "./styles/partials/typography" as *;

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

body {
  @include mobile-background;

  @include desktop {
    @include desktop-background;
  }
}

a {
  width: fit-content;
}