@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.scroll-button {
    padding: 0.15rem;
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    transition: transform 0.1s ease;
    cursor: pointer;
    outline: none;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(255,173,244,0.5);
    color: $basic-black;
    font-size: 1.5rem;
    transition: 0.5s;

    &:hover {
        background-color: $basic-white;
        transform: translate(-2px, -2px);
    }

    &:active {
        transform: translate(2px, 2px);
    }

    @include desktop {
        padding: 0.25rem 0;
        bottom: 5.25rem;
        right: 5rem;
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        text-align: center;
    }

    &__container {
        color: $basic-white;
    }
}