@use "./breakpoints" as *;
@use "./colors" as *;
@use "./typography" as *;

@mixin header-button {
    background: none;
    border: none;
    color: $basic-black;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    height: 2.25rem;
    line-height: 1.6875rem;
    text-transform: uppercase;
    min-width: 3.375rem;
    padding: 0 1rem;
    text-align: center;
    transition: 0.3s;
    &:hover {
        background-color: $basic-black;
        color: $basic-white;
    }
}

@mixin body-button {
    background: none;
    border: none;
    color: $basic-white;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    height: 2.25rem;
    line-height: 1.6875rem;
    text-transform: uppercase;
    min-width: 3.375rem;
    padding: 0 1rem;
    text-align: center;
    transition: 0.3s;
    &:hover {
        background-color: $basic-white;
        color: $basic-black;
    }
}

@mixin desktop-margins {
    margin: 0 auto;
    max-width: 75rem;
}

@mixin mobile-background {
    margin: 0;
    width: 100%;
    height: 100vh;
    background-image:
        linear-gradient(to right, $background-gray 1px, transparent 1px),
        linear-gradient(to bottom, $background-gray 1px, $basic-white 1px);
    background-size: 2rem 2rem;
    background-position: center center;
}

@mixin desktop-background {
    margin: 0;
    width: 100%;
    height: 100vh;
    background-image:
        linear-gradient(to right, $background-gray 1px, transparent 1px),
        linear-gradient(to bottom, $background-gray 1px, $basic-white 1px);
    background-size: 3rem 3rem;
    background-position: center center;
}