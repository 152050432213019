@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.contact {
    background-color: $basic-black;

    @include desktop {
        padding: 3rem 0 5rem;
    }

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        @include desktop {
            @include desktop-margins;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__freelance-content-container {

        @include desktop {
            display: flex;
            align-items: center;
        }
    }

    &__freelance {
        margin: 0 1rem 2.75rem;
        margin-bottom: 2.75rem;
        padding: 1rem 0;
        border-top: 2px solid $secondary-pink;
        border-bottom: 2px solid $secondary-pink;
        font-size: 1.25rem;
        text-transform: uppercase;
        text-align: center;
        color: $basic-white;

        @include desktop {
            margin: 0;
            padding: 1rem 0 2.75rem;
            border-top: none;
            border-bottom: none;
            font-size: 2.25rem;
            text-decoration: underline;
            text-underline-offset: 1rem;
            text-decoration-color: $secondary-pink;
        }

        &--hidden {
            display: none;

            @include desktop {
                padding: 1rem 0 2.75rem;
                display: flex;
                font-size: 2.25rem;
                color: $basic-white;
                text-decoration: underline;
                text-underline-offset: 1rem;
                text-decoration-color: $secondary-pink;
            }
        }
    }

    &__freelance-content {
        font-size: 1rem;
        color: $basic-white;

        @include desktop {
            font-size: 1.25rem;
        }
    }

    &__freelance-line-one {

        @include desktop {
            padding: 0 0 0.75rem 0;
        }
    }

    &__freelance-line-two {
        padding: 1rem 0 0 0;

        @include desktop {
            padding: 0 0 2rem 0;
        }
    }

    &__content-container {
        margin: 0 1rem;
        text-align: center;

        @include desktop {
            margin: 0;
            text-align: left;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
    }

    &__email {
        margin: 2rem 0 0 0;
        width: 100%;
        font-size: 1rem;
        color: $secondary-pink;
        text-decoration: none;

        @include desktop {
            margin: 0 0 2rem 0;
            border-bottom: 3px solid transparent;
            font-size: 1.25rem;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 3px;
            text-decoration-color: $secondary-pink;
            text-underline-offset: 0.45rem;
        }
    }

    &__linkedin {
        padding: 1rem 0 0.5rem 0;
        width: 100%;
        font-size: 1rem;
        color: $basic-white;
        text-decoration: none;

        @include desktop {
            padding: 0 0 0.5rem 0;
            font-size: 1.15rem;
        }

        &:hover {
            color: $secondary-pink;
        }
    }

    &__github {
        margin-bottom: 2rem;
        width: 100%;
        font-size: 1rem;
        color: $basic-white;
        text-decoration: none;

        @include desktop {
            margin-bottom: 0;
            font-size: 1.15rem;
        }

        &:hover {
            color: $secondary-pink;
        }
    }

    &__logo {
        margin-bottom: 2rem;
        width: 3.5rem;

        @include desktop {
            margin-bottom: 0;
            width: 5rem;
            align-self: flex-end;
        }
    }
}