
.scroll-down {
    cursor: pointer;

    &__arrow {
        width: 4rem;
        animation: bounce 2s infinite;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
    }
    40% {
    transform: translateY(-10px);
    }
    60% {
    transform: translateY(-5px);
    }
}