@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease;
    width: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 50%;
    background-color: transparent;
    transform: translateX(-50%);

    @include desktop {
        padding: 2rem 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__nav {
        margin: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        
        @include desktop {
            margin: 0;
            border: none;
            justify-content: space-evenly;
        }
    }

    &__button {
        background: none;
        border: none;
        color: $basic-black;
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: 600;
        height: 2.25rem;
        line-height: 1.6875rem;
        text-transform: uppercase;
        text-align: center;
        transition: 0.3s;
        &:hover {
            background-color: $basic-black;
            color: $basic-white;
        }

        @include desktop {
            @include header-button;
        }

        &--spaced {
            margin-left: 1rem;
        }
    }

    &.scrolled {
        background-color: $basic-black;
        color: $basic-white;

        .header__button {
            color: $basic-white;

            &:hover {
                background-color: $basic-white;
                color: $basic-black;
            }
        }
    }
}

